<template>
  <div
    v-if="!isHidden"
    class="bg-blue-600 p-20 mt-16 mb-32 lg:m-0 lg:fixed lg:bottom-112 lg:right-0 lg:z-40 lg:rounded-l-large"
  >
    <div class="">
      <button
        class="float-right hidden size-24 rounded-full lg:block"
        @click.prevent="quickLinksClose"
      >
        <SpriteSymbol name="cancel" class="size-full text-white" />
      </button>

      <p class="typo-h4 font-condensed text-white">{{ title }}</p>

      <DrupalLink v-if="to" :to="to" class="button is-primary mt-20">
        <div v-if="page === 'home'" class="icon">
          <SpriteSymbol name="lock" />
        </div>
        <span>{{ linktext }}</span>
      </DrupalLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLocalStorage } from '@vueuse/core'

const isHiddenValue = useLocalStorage<'yes' | 'no'>('quickLinksVisible', 'no')

const isHidden = computed(() => isHiddenValue.value === 'yes')

defineProps<{
  to: string
  title: string
  linktext: string
  page: string
}>()

function quickLinksClose() {
  isHiddenValue.value = 'yes'
}
</script>
