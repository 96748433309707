<template>
  <BlokkliProvider v-bind="blokkliProps" :entity="props">
    <div class="is-wide container relative mt-32">
      <div class="image is-rounded aspect-16/9 bg-gray-100">
        <MediaImage
          v-if="heroImage"
          v-bind="heroImage"
          :image-style="imageStyle"
        />

        <form
          class="mt-24 w-full rounded-large bg-white p-24 shadow-xl md:absolute md:-bottom-24 md:left-1/2 md:mt-0 md:max-w-screen-xs md:-translate-x-1/2"
          @submit.prevent="search"
        >
          <p class="mb-24 font-condensed text-3xl">
            {{ $texts('whatAreYouSearching', 'What are you looking for?') }}
          </p>
          <SearchInput id="search-home" v-model="searchTerm" />
        </form>
      </div>
    </div>

    <PageHeader :title="title" :lead="intro" :tagline="leadTitle" />

    <ClientOnly>
      <QuickLinks
        v-if="myPublicaLink?.url?.path"
        :to="myPublicaLink.url?.path"
        :title="
          $texts(
            'homepageLinkMessage',
            'Your personal occupational benefits portal',
          )
        "
        linktext="myPublica"
        :page="'home'"
      />
    </ClientOnly>

    <BlokkliField
      :list="paragraphs"
      name="field_paragraphs"
      class="page-home-teasers"
    />
  </BlokkliProvider>
</template>

<script setup lang="ts">
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  intro?: string
  leadTitle?: string
  pageColor?: string
  hasBackground?: boolean
  paragraphs?: NodePageFragment['paragraphs']
  body?: string
  blokkliProps?: NodePageFragment['blokkliProps']
}>()

const searchTerm = ref('')

const router = useRouter()
const initData = await useInitData()
const { pushEvent } = useAnalytics()

const { data: heroImage } = await useAsyncGraphqlQuery(
  'homepageHeroimage',
  null,
  {
    transform: function (data) {
      return data.data.config?.image
    },
    graphqlCaching: {
      client: true,
    },
  },
)

const myPublicaLink = computed(() => {
  return initData.value.siteConfig.pageMypublica
})

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 770,
    sm: 984,
    md: 1380,
    lg: 1380,
  },
})

const search = () => {
  pushEvent({
    event: 'site_search',
    search_term: searchTerm.value,
  })
  router.push({
    name: 'search',
    query: {
      text: searchTerm.value,
    },
  })
}
</script>

<style lang="postcss">
.page-home-hero-image {
  @screen md {
    margin-top: 2rem;
  }
}
.page-home-hero-search {
  @screen sm {
    max-width: 35rem;
    margin: 0 auto;
    margin-top: -3rem;
  }
  @screen md {
    margin-top: -6rem;
  }
  @screen lg {
    margin-top: -10rem;
  }
}
.page-home-teasers {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  grid-gap: calc(var(--grid-gap) * 2);
}
</style>
